import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import './index.css';
import Pagination from '../Layouts/Pagination'


export default class Items extends Component {
    state = {
        items : [],
        loading: true,
        currentPage: 1,
        totalPages: 0,
        nextPage: '',
        data: {}
    }

    componentDidMount()
    {
        const { batch } = this.props.match.params
        const  token = sessionStorage.getItem("token");
        const key = CryptoJS.HmacSHA512(token, "123NDSx").toString();
        
        const data = {
            token: token,
            action: 'get_items',
            page: '1',
            batch_id: batch,
            passcode: key
        };

        this.setState({
            data
        });

        this.getItems(data);

    }
    
    paginate = (pageNumber) =>
    {
        this.setState({
            loading : true,
        })

        const { batch } = this.props.match.params

        const  token = sessionStorage.getItem("token");
        const key = CryptoJS.HmacSHA512(token, "123NDSx").toString();

        const data = {
            token: token,
            action: 'get_items',
            page: pageNumber,
            batch_id: batch,
            passcode: key
        }

        this.getItems(data);
    }

    prev = () => {
        this.setState({
            loading : true,
        })

        const { batch } = this.props.match.params
        const  token = sessionStorage.getItem("token");
        const key = CryptoJS.HmacSHA512(token, "123NDSx").toString();
        let prevPage = this.state.currentPage;
        prevPage--

        const data = {
            token: token,
            action: 'get_items',
            page: prevPage,
            batch_id: batch,
            passcode: key
        }
        
        this.getItems(data);
    }


    next = () => {
        this.setState({
            loading : true,
        })

        const { batch } = this.props.match.params
        const  token = sessionStorage.getItem("token");
        const key = CryptoJS.HmacSHA512(token, "123NDSx").toString();
        let nextPage = this.state.currentPage;
        nextPage++

        const data = {
            token: token,
            action: 'get_items',
            page: nextPage,
            batch_id: batch,
            passcode: key
        }
        
        this.getItems(data);
    }

    async getItems(data)
    {
        

        try {
           
            const response = await fetch('https://irecharge.com.ng/api/payroll/get_items.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            const jsonResponse = await response.json();
            // console.log(jsonResponse)

            if (jsonResponse.status === '0') {
                  this.setState({
                    items: jsonResponse.items,
                    currentPage: jsonResponse.current_page,
                    totalPages: jsonResponse.total_pages,
                    nextPage: jsonResponse.next_page,
                    loading : false,
                })
                // this.props.history.push('/');
            } 
            else if (jsonResponse.status === '-1')
            {
                Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'No record found'
				  });
				this.props.history.push('/History')
            }
        } catch (error) {
              this.setState({
                
            })
        }
    }

    handleBack = () => {
     console.log('click');
     
        this.props.history.push('/History')
    }
    

    render() {

        return (
           <div className="t-style">
              { this.state.loading ? <div class="lds-facebook-item"><div></div><div></div><div></div></div> :
            <table className="table">
                <button className="btn-primary btn-sm" onClick={() => this.handleBack()}>Back</button>
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Title</th>
                    <th scope="col">Company</th>
                    <th scope="col">Code</th>
                    <th scope="col">Bank</th>
                    <th scope="col">Account No</th>
                    <th scope="col">Transaction Id</th>
                    <th scope="col">Status</th>
                    <th scope="col">Batch id</th>
                    <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {/* loop here */}
                    {this.state.items.map(
                        item => {
                            return(
                            <tr>
                            <td>{item.payee_name}</td>
                            <td>{item.amount}</td>
                            <td>{item.title}</td>
                            <td>{item.company}</td>
                            <td>{item.code}</td>
                            <td>{item.bank}</td>
                            <td>{item.account_number}</td>
                            <td>{item.transaction_id}</td>
                            <td>{item.status}</td>
                            <td>{item.batch_id}</td>
                            <td>{item.date}</td> 
                            </tr>
                               
                            )
                        }
                    )}
                   
                </tbody>
                <Pagination totalPage = {this.state.totalPages} currentPage = {this.state.currentPage} paginate = {this.paginate} prev = {this.prev} next = {this.next} nextpage = {this.state.nextPage}/>
                </table>
                }
           </div>
        )
    }
}
