import React, {Component} from 'react';
import './index.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '../Layouts/Header';
import History from '../History/index';
import Uploads from './Uploads';
import Success from './Success';
import Failed from './Failed';
import './index.css';
import Items from '../History/Items';
// import index.css from './components/dashboard/index.js'

export default class Dashboard extends Component {
    state = { 
        answer: '' 
    }


    handlePay = (event) => {
        this.setState({ answer: event.target.name });
    }


    render() {
        return (
            
            <div className = "app-main__outer" >
                    <Router>
                <Header {...this.props}/>
                <div class="app-main__inner" >
                <Switch>
                    <Route path='/History/:batch' component={Items} />
                    <Route path='/History' component={History} />
                    <Route path='/success' component={Success} />
                    <Route path='/failed' component={Failed} />
                    <Route path='/history' component={History} />
                    <Route path='/:batch' component={Items} />
                    <Route exact path='/' component={Uploads} />
                </Switch> 
                </div>
                </Router>

            </div>
            
        )
    }
}
