import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import './Success.css'

 class Success extends Component {
    state={
    click:false
    }
    handleClick = () =>{    
        this.props.history.push('/');
       }

    render() {
        const totalAmount=sessionStorage.getItem("total_amount")
        const totalNumber=sessionStorage.getItem("total_no")
        const title=sessionStorage.getItem("title")
        return (
            <div>
              <div className>
                  <img alt="success" src="img/success.png" height="200px" width="200px" className="successimg"/>
                  <div>
                    <p>Congratulations, Your salary payment was successful</p> 
                    <div>
                       <p className="title">Payment details</p> 
                       <div class="details">
                       <b >Payment Description :</b>&nbsp;&nbsp;{title}
                       <br/>
                      <b>Number of staff Payed :</b>&nbsp;&nbsp;{totalNumber}
                      <br/>
                      <b>Total amount payed :</b> &nbsp;&nbsp; ₦{totalAmount}
                      </div>
                    </div>  
                  </div>
              </div>
              <br/>
              <div className="buttondiv">
              <button type="button" class="btn btn-primary" onClick={this.handleClick}>Home</button>
              </div>
            </div>
        )
    }
}
export default withRouter(Success)
