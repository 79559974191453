import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ totalPage, currentPage, paginate, prev, next, nextpage}) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }
  console.log('total', totalPage);
  console.log('current', currentPage);
  console.log('page', pageNumbers);
  
  console.log(totalPage, currentPage, pageNumbers)
  let prevButton;
  let nextButton;


  if(nextpage != null)
  {
    nextButton = <li class="page-item"><Link class="page-link" onClick={() => next()}>Next</Link></li>
  }
  else
  {
    nextButton = ''
  }

  if(currentPage > 1)
  {
    prevButton = <li class="page-item"><Link class="page-link" onClick={() => prev()}>Previous</Link></li>
  }
  else
  {
    prevButton = ''
  }

  return (
    <nav>
      <ul className='pagination'>{}
      {prevButton}
        {pageNumbers.map(number => (
          <li key={number} className={'page-item ' + (currentPage === number ? 'active' : '')}>
            <Link onClick={() => paginate(number)} className='page-link'>
              {number}
            </Link>
          </li>
        ))}
        {nextButton}
      </ul>
    </nav>
  );
};

export default Pagination;