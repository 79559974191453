import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
import { withRouter } from 'react-router-dom'
import { ExcelRenderer } from 'react-excel-renderer';
import { Jumbotron, Col, Input, InputGroup, InputGroupAddon, FormGroup, Button, Fade, FormFeedback, Container, Card } from 'reactstrap';
import Loader from '../Loader/index.js';
import './index.css';
import Swal from 'sweetalert2';

class Uploads extends Component {
  constructor(props){
    super(props);
    this.state={
      isOpen: false,
      dataLoaded: false,
      isFormInvalid: false,
      rows: null,
      cols: null,
      resultantObj: null,
      showUploadBtn: true,
      totalAmount: '',
      totalRowsOfData: '',
      title:'',
      jumbo_text : 'Click on Browse to select a .xlsx file only for uploads',
      titleError: '',
      loading: false
    }
    this.fileHandler = this.fileHandler.bind(this);
    this.toggle = this.toggle.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.openNewPage = this.openNewPage.bind(this);
    this.fileInput = React.createRef();
  }


  renderFile = (fileObj) => {
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if(err){
          console.log(err);            
        } else {

          const headers = resp.rows.shift();
          const checkHeaders = ["S/N", "Name", "Company", "Bank Code", "Bank", "Account Number", "Amount"];
          if (JSON.stringify(headers)!== JSON.stringify(checkHeaders)) {
            Swal.fire('Invalid file format')
          } else {
            console.log(headers)
            const customHeader = ['S/N', 'name', 'company', 'code', 'bank', 'account_number', 'amount']
            let resultObj = this.convertToObject(resp.rows, customHeader);
            console.log(resultObj);
             // pass the array to be converted into  an object
            this.setState({
              dataLoaded: true,
              cols: headers,
              rows: resp.rows,
              showUploadBtn: false,
              resultantObj: resultObj,
              jumbo_text: ''
            });
            this.getTotalAmount();
          }
        }
      }); 
  }


  revertUpload = () => {
    this.setState({ 
      showUploadBtn: true,
      jumbo_text: 'Click on Browse to select a .xlsx file only for uploads',
      // titleError: 'Please provide a title.'
    });
  }


  convertToObject = (data, header) => {
    
    // convert the response from an array of array to an array of object
    var keys = header,
    i = 0, k = 0,
    obj = null,
    output = [];
    for (i = 0; i < data.length; i++) {
    console.log('data', data);
      obj = {};

      for (k = 0; k < keys.length; k++) {
          obj[keys[k]] = data[i][k];
          delete obj['S/N']
      }


      output.push(obj);
    }
    return output;
    // ends converting the response from an array of array to an array of object
  }

    getTitle = (e) => {
      let title = e.target.value;
      this.setState({
        title: title,
        titleError: ''
      })
    }
  
  
  handleUploadFile = async() => {
    this.setState({
      loading: true
    })
    const token = sessionStorage.getItem("token")
    const { totalAmount, totalRowsOfData, resultantObj, title } = this.state
    const access = CryptoJS.HmacSHA512(token, "123NDSx").toString();

    const data = {
      token: token,
      action: 'upload',
      total_amount: totalAmount,
      total_no: totalRowsOfData,
      title: title,
      passcode: access,
      items: resultantObj
    }
    const response = await fetch('https://irecharge.com.ng/api/payroll/upload.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });
    const JsonResponse = await response.json();
    // console.log(JsonResponse);
    let res = JsonResponse.status 
    if(res === '0') {
      sessionStorage.setItem("total_amount", this.state.totalAmount);
      sessionStorage.setItem("total_no", this.state.totalRowsOfData);
      sessionStorage.setItem("title", this.state.title);
      this.props.history.push('/success');
    } else {
      sessionStorage.setItem("failedReason", JsonResponse.message)
      this.props.history.push('/failed');
    }
  }

    fileHandler = (event) => {    
      if(event.target.files.length) {
        let fileObj = event.target.files[0];
        let fileName = fileObj.name;

        //check for file extension and pass only if it is .xlsx and display error message otherwise
        if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
          this.setState({
            uploadedFileName: fileName,
            isFormInvalid: false,
          });
      
          this.renderFile(fileObj);
        } else {
          this.setState({
            isFormInvalid: true,
            uploadedFileName: ""
          })
      }
    }}

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    }); 
  }


  openFileBrowser = () => {
    const { title } = this.state;
     if (title !== '') {
       this.fileInput.current.click();
     } else {
       this.setState({
         titleError: 'Please provide a title.'
       })
     }
  }
 

  openNewPage = (chosenItem) => {
    const url = chosenItem === "github" ? "https://github.com/ashishd751/react-excel-renderer" : "https://medium.com/@ashishd751/render-and-display-excel-sheets-on-webpage-using-react-js-af785a5db6a7";
    window.open(url, '_blank');
  }


  getTotalAmount = () => {
    let totalAmount = 0;
    let totalRowsOfData;
    if (this.state.dataLoaded === true) {
      const tableData = [...this.state.rows];
      totalRowsOfData = tableData.length;
      tableData.map(sli => {
        return (
          totalAmount+= parseInt(sli[6])
        )
	  })
	}
    this.setState({
      totalAmount,
      totalRowsOfData
    })    
  }


  render() { 
    return (
      this.state.loading 
      ? 
        <Loader /> 
      : 
      <div>
        <div>
          <Jumbotron className="jumbotron-background">          
              <h3 className="s4">{this.state.jumbo_text}</h3>
          </Jumbotron>
        </div>
        <Container>
          
        	{ this.state.showUploadBtn === true ?
				<form>
        <FormGroup row>
        <Col xs={4} sm={8} lg={10} className="s19">
        <InputGroup className="s5" style={{width:'100%', position:'relative', marginBottom: '15px'}}>
          <label><strong>Upload description:<span className="s21"> *</span></strong>&nbsp;&nbsp;</label>
          <input type ="text" className="form-control" value={this.state.title} placeholder="" onChange={this.getTitle}/>
          { this.state.titleError === '' ? '' : <div className="s20">*{this.state.titleError}</div>}
          </InputGroup>     
						<InputGroup className="s5">
						<InputGroupAddon addonType="prepend">
							<Button className="s18" onClick={this.openFileBrowser.bind(this)}><i className="cui-file s6"></i> Browse&hellip;</Button>
							<input type="file" hidden onChange={this.fileHandler.bind(this)} ref={this.fileInput} onClick={(event)=> { event.target.value = null }} className="s7" />                                
						</InputGroupAddon>
						<Input type="text" className="form-control" value={this.state.uploadedFileName} readOnly invalid={this.state.isFormInvalid} />                                              
						<FormFeedback>    
							<Fade in={this.state.isFormInvalid} tag="h6" className="s8">
							Please select a .xlsx file only !
							</Fade>                                                                
						</FormFeedback>
						</InputGroup>     
					</Col>                                                   
					</FormGroup>   

          <div>
            <a className ="btn btn-default s17 s16" href={process.env.PUBLIC_URL + '/Sample Payroll Sheet.xlsx'}>Download sample upload file</a>
            <a className ="btn btn-default s17 s16" href={process.env.PUBLIC_URL + '/Payroll Bank Codes.xlsx'}>Download bank codes</a>
          </div>
				</form> : ""}

			{this.state.dataLoaded && 
			<div>
				{ this.state.showUploadBtn === false ?
					<div className="s9"> 
          <div className="row s10">
          	<div className="s11">
						<button type="button" className="btn btn-success s13" name="pay" onClick={this.revertUpload}  >Back</button>
						<button type="button" className="btn btn-success" name="pay" onClick={this.handleUploadFile}>Pay</button>
					</div>
          </div>
          <div className="row">
          <p className="s12">Number of staff: {this.state.totalRowsOfData}</p>
						<p className="s14">Total amount: ₦ {this.state.totalAmount}</p>
          </div>
           
					
					<Card body outline color="secondary" className="restrict-card s15">
						<table className="table table-condensed">
              <thead>
                <tr>
                { this.state.cols.map((element, index) => {
                  return (
                    <th key={index}>{element}</th>
                  )
                })}
                </tr>
							</thead>
              <tbody>
							{ this.state.rows.map((tableRow, index) => {
								return (
									<tr key={index}>
										{tableRow.map((element, index) => {
										return (
											<td key={index}>{element}</td>
										)})}
									</tr>
								)
							})}
							</tbody>
						</table>
					</Card>
				</div>  : "" }
			</div>}
      </Container>
      </div>
    );
  }
}

export default withRouter(Uploads);
