import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
import Logo from '../../assets/images/logo1.png';
import Swal from 'sweetalert2';
import './login.css';


export default class Login extends Component {
    state = {
		username: '',
        password: '',
        loading: false
    }

    handleChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            [name]: value,
        });
    }

    //Call login Api here and save user session data.
    handleLogin = async(e) => {
        e.preventDefault();
        const{username, password}=this.state
        if(username=='' || password == ''){
            Swal.fire({
                text: 'All fields are required!'
            })
            this.setState({
                loading: false,
            })
        } else {
            this.setState({
                loading: true
            });
            try {
                const { username, password } = this.state;
                const key = CryptoJS.HmacSHA512(username, "123NDSx").toString();
    
                   const data = {
                    username: username,
                    action: 'login',
                    password: password,
                    passcode: key
                }
    
                const response = await fetch('https://irecharge.com.ng/api/payroll/login.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                });
                const jsonResponse = await response.json();
    
                if (jsonResponse.status === '0') {
                    //save session data
                    sessionStorage.setItem("token", jsonResponse.token);
                    sessionStorage.setItem("isUserLogged", true);
                    Swal.fire({
                        icon: 'success',
                        text: 'Login successful',
                        position: 'top-end',
                        showClass: {
                          popup: 'animated fadeInDown faster'
                        },
                        hideClass: {
                          popup: 'animated fadeOutUp faster'
                        }
                      });
                      this.setState({
                          loading: false
                      })
                    this.props.history.push('/');
                } else if (jsonResponse.status === '-1') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Incorrect login credentials!'
                    })
                    this.setState({
                        loading: false
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                  });
                  this.setState({
                    loading: false
                });
            }
        }
    }


    render() {
        const { username, password, loading } = this.state;
        return (
            <div className = "container">
            <div className="tab-content">
                    <div className="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                        <div className="row">
                            <div className="col-md-5" style={{marginLeft: "auto", marginRight: "auto", marginTop:"40px"}}>
                                <img src={Logo} alt="iRecharge Logo" className="center-block"/>
                                <div className="main-card mb-3 card">
                                    <div className="card-body">
                                        <h5 className="card-title">Login</h5>
                                        <form className="">
                                            <div className="position-relative form-group">
                                                <label htmlFor="exampleEmail" className="">Username</label>
                                                <input name="username" id="exampleEmail" placeholder="Username" className="form-control" value={username} onChange={this.handleChange} required/>
                                            </div>

                                            <div className="position-relative form-group">
                                                <label htmlFor="examplePassword" className="">Password</label>
                                                <input name="password" id="examplePassword" placeholder="password" type="password" className="form-control" value={password} onChange={this.handleChange} required/>
                                            </div>
                                            
                                            { loading ? <div class="lds-facebook"><div></div><div></div><div></div></div> : <button className="btn btn-success btn-lg" onClick={this.handleLogin}>Login</button> }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
        )
    }
}
