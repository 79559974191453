import React from 'react';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { ProtectedRoute } from './components/ProtectedRoute'

import {
  Route,
  Switch,
  Redirect, 
} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Switch>
        <ProtectedRoute exact path="/"  component={Dashboard} />
        <Route path="/login" component={Login} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;