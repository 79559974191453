import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './logo.png';
// import logo from './logo.png'

export class Header extends Component {
   
    handleLogOut = (e) => {
        e.preventDefault();
        
        sessionStorage.clear();
        this.props.history.push('/login');
    }
    

    render() {
        return (
            
            <div className="app-header header-shadow"> 
                <div className="app-header__logo"> 
                    <div className="logo-src"></div>
                </div>
        
                {/* look here  */}
         
            {/* from here */}
            <div className="app-appheader">
                
                <div className="app-header-left">
                    
                    <ul className="header-menu nav">
                        <li className="nav-item">
                            <Link to={'/'} className="nav-link">
                                <i className="nav-link-icon fa fa-database"> </i>
                                Dashboard
                            </Link>
                        </li>
        
                        <li className="btn-group nav-item">
                            <Link to={'History'} className="nav-link">
                                <i className="nav-link-icon fa fa-history"></i>
                                History
                            </Link>
                        </li>
                        <li className="dropdown nav-item">
                            <Link className="nav-link" onClick = {this.handleLogOut}>
                                <i className="nav-link-icon fa fa-power-off"></i>
                                Logout
                            </Link>
                        </li>
                    </ul> 
                    
                    </div>
                    
                
            </div>
            {/* to here */}

            
                            
              
            </div>
        )
    }
}


export default Header