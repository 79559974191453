import React, { Component } from 'react'

export default class Failed extends Component {
    render() {
        const message = sessionStorage.getItem('failedReason')
        return (
        
            <div>
                <img alt="failed" src="img/failed.png" height="200px" width="200px" className="successimg"/>

                <p>{message}</p>
            </div>
        )
    }
}
