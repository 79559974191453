import React from 'react';
import './loader.css';

export default function Loader() {
    return (
        <div className="page-loader">
        <div class="lds-facebook"><div></div><div></div><div></div></div>
          <div>Loading...</div>
        </div>
      )
}
