import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import Loader from '../Loader/index.js';
import Pagination from '../Layouts/Pagination';
import './index.css';


export class History extends Component {
    constructor (props) {
        super (props);
        this.state = {
            transactions: [],
			loading: false,
			currentPage: 1,
			totalPages: 0,
			nextPage: '',
			batchId: '',
			data: {}
        }
	}
	

	async componentDidMount () {
			this.setState({
                loading: true
			});
			
			const token = sessionStorage.getItem('token');
			const passcode = CryptoJS.HmacSHA512(token, "123NDSx").toString();

			const data = {
				token,
				action: "get_batches",
				page: "1",
				passcode
			};

			this.setState({
				data
			});

			this.handleGetBatch(data);
	}


	handleGetBatch = async(data) => {
		if(localStorage.getItem('transaction') !== null) {
			const data = JSON.parse(localStorage.getItem('transaction'));
			const currentPage = localStorage.getItem('page')
			const totalPage = localStorage.getItem('totalPages')
			const nextPage = localStorage.getItem('nextPage')
			this.setState({
				loading: false,
				transactions: data, 
				nextPage: nextPage,
				totalPages: totalPage,
				currentPage: currentPage
			});
			localStorage.clear()
		} else {
			try {	
				const result = await fetch('https://irecharge.com.ng/api/payroll/get_batches.php', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data)
				});
				
				const response = await result.json();
				if (response.status === '0') {				
					this.setState({
						transactions: response.items,
						currentPage: response.current_page,
						totalPages: response.total_pages,
						nextPage: response.next_page,
						loading: false
					});
				} else if (response.status === '-1') {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'No record found'
					  });
					  this.setState({
						loading: false
					});
				}
			} catch (error) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong!'
				  });
				  this.setState({
					loading: false
				});
			}
		}


	}


	paginate = (pageNumber) => {
        this.setState({
            loading : true,
        })

        const pagedData = {...this.state.data}
		pagedData.page = pageNumber;
		
        this.handleGetBatch(pagedData);
    }


	handleNextPage = () => {
        this.setState({
            loading : true,
        })

        const nextData = {...this.state.data};
        
		let nextPage = this.state.currentPage;
		nextPage++

        nextData.page = nextPage;
        
        this.handleGetBatch(nextData);
	}
	

	handlePrevPage = () => {
        this.setState({
            loading : true,
        })

		const prevData = {...this.state.data};
		
		let prevPage = this.state.currentPage;
		prevPage--

		prevData.page = prevPage;
        
        this.handleGetBatch(prevData);
	}
	

	handleView = (batchId) => {
		const { currentPage, transactions, totalPages, nextPage } = this.state;
		const jsonTransaction = JSON.stringify(transactions)
		this.setState({
			batchId
		}); 
		localStorage.setItem('transaction', jsonTransaction);
		localStorage.setItem('page', currentPage)
		localStorage.setItem('totalPages', totalPages)
		localStorage.setItem('nextPage', nextPage)
	}



    render() {
		const {transactions, loading} = this.state;
			return (  
				loading 
				? 
					<Loader /> 
				: 
					( !transactions.length
						? 
							<h1>No transactions found</h1>
						:
							<div className="dashboard-transaction">
								<table id="trans-table">
									<thead id="table-info">
										<tr className="table-info">
											<th scope="col">Title</th>
											<th className="table-data" scope="col">Batch ID</th>
											<th scope="col">Date</th>
											<th scope="col">Amount</th>
											<th scope="col">Total Number</th>
											<th scope="col">Total Successful</th>
											<th>View</th>																											
										</tr>
									</thead>
									<tbody className="t-body">
										{transactions.map((transaction, index) => {
												return (
														<tr key={index}>
															<td>{transaction.title}</td>
															<td>{transaction.batch_id}</td>
															<td>{transaction.date}</td>
															<td>{transaction.amount}</td>
															<td>{transaction.total_no}</td>
															<td>{transaction.total_successful}</td>
															<td><Link className="viewLink" to={`/${transaction.batch_id}`}><button onClick={() => this.handleView(transaction.batch_id)} className="btn btn-success">View</button></Link></td>   
														</tr>
													)
												}
											)
										}
									</tbody>  
									<Pagination totalPage = {this.state.totalPages} currentPage = {this.state.currentPage} paginate = {this.paginate} prev = {this.handlePrevPage} next = {this.handleNextPage} nextpage = {this.state.nextPage}/>   
								</table>
							</div>
						)
				)    
  	}
}

export default History
